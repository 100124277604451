import { logger } from '@hatchd/utils';
import * as Sentry from '@sentry/nextjs';
import { AbilityContext } from 'components/can';
import { ErrorFallback } from 'components/error-fallback';
import { Header } from 'components/header';
import { Main, Main as LayoutMain } from 'components/layout';
import { NavBar, NavBarItem } from 'components/nav-bar';
import { ToastContainer } from 'components/ui';
import { UpgradeDialogProvider } from 'components/upgrade-dialog';
import { UserTermsDialog } from 'components/user-terms-dialog';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useBuyerOrg } from 'hooks/use-buyer-org';
import { useUser } from 'hooks/use-user';
import { useVersionMatch } from 'hooks/use-version-match';
import defaultAbility, { AppAbility, defineAbility } from 'lib/ability';
import { getUserName } from 'lib/helpers';
import { useStore } from 'lib/store';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import React, { FC, useEffect, useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { PATHS } from 'settings/config';
import styled, { css, CSSProperties } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { breakpoints } from '../../styles/breakpoints';
import PublicHeader from '../header/public-header';
import { useMedia } from 'react-use';
import { BottomNavBar } from 'components/bottom-nav-bar';
import { useRouter } from 'next/router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface AppLayoutProps {
  overrideHeaderContent?: boolean;
  hideNav?: boolean;
  hideHeader?: boolean;
  hideHomeLink?: boolean;
  marketplace?: boolean;
  app?: boolean;
  setTokenExpiry: (token: string) => void;
}

const buyerItems: NavBarItem[] = [
  {
    title: 'Home',
    icon: 'HutIcon',
    href: PATHS.home,
  },
  {
    title: 'Stockbook',
    icon: 'StockbookNewIcon',
    href: PATHS.stockbook.home,
  },
  {
    title: 'Stock Required',
    icon: 'StockRequiredIcon',
    href: PATHS.stockRequired.home,
  },
  {
    title: 'PIC Manager',
    icon: 'PicManagerIcon',
    href: PATHS.picManager.home,
  },
  {
    title: 'Contacts',
    icon: 'AddContact',
    href: PATHS.contacts.home,
  },
  {
    title: 'Networks',
    icon: 'AddToGroupIcon',
    href: PATHS.privateGroups.home,
  },
  /** Leaving it commented here for LPD-3771 */
  // {
  //   title: 'My Prices',
  //   icon: 'PriceTagIcon',
  //   href: PATHS.prices.home,
  // },
  {
    title: 'Price Grid PDF creator',
    icon: 'GridWizardIcon',
    href: PATHS.summaryGrids.home,
  },
  /** Leaving it commented here for LPD-2656 */
  // {
  //   title: 'Contracts',
  //   icon: 'PageIcon',
  //   href: PATHS.offers.home,
  // },
];

const sellerItems: NavBarItem[] = [
  {
    title: 'Marketplace',
    icon: 'Marketplace',
    href: PATHS.marketplace.listings,
  },
  {
    title: 'My Sell listings',
    icon: 'MyListingsIcon',
    href: PATHS.marketplace.sellListings,
  },
  {
    title: 'My Buy listings',
    icon: 'MyListingsIcon',
    href: PATHS.marketplace.buyListings,
  },
];

const mainItems: NavBarItem[] = [
  {
    title: 'Company Profile',
    icon: 'BusinessIcon',
    href: PATHS.organisation.profile,
  },

  {
    title: 'Help',
    icon: 'HelpIcon',
    href: PATHS.help,
  },
];

const navVariants: Variants = {
  visible: {
    x: 0,
    opacity: 1,
    transitionEnd: { position: 'relative' },
  },
  hidden: { x: -16, opacity: 0 },
};

const WrapperGrid = styled.div<{
  hideHeader: boolean;
  marketplace: boolean;
  app: boolean;
  session: Session | null;
}>`
  ${({ marketplace, hideHeader, session }) =>
    !marketplace
      ? css`
          grid-template-rows: ${hideHeader ? '1fr' : 'min-content 1fr'};
          display: grid;
          height: ${session ? globalTheme.sizes.fullHeight : '100%'};
          overflow: hidden;

          @media screen and (max-width: ${breakpoints.small}) {
            display: inherit;
          }
        `
      : css`
          grid-template-rows: '1fr';
          display: flex;
          flex-direction: column;
        `};

  /* Set the max height of the inner layout container when the header is hidden */
  ${LayoutMain} {
    ${({ hideHeader }) =>
      hideHeader &&
      css`
        max-height: ${globalTheme.sizes.fullHeight};
      `}
  }

  @media print {
    height: 100%;
    overflow: unset;
  }

  ${({ app }) =>
    !!app &&
    css`
      overflow: scroll;
    `}
`;

// This is to wrap the BottomNavBar in a styled() factory so it is
// eligible for a styled component interpolation and targeted by other styled components.
const StyledNavBar = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
  left: 8px;
  z-index: ${globalTheme.zIndices.nav};
  height: 80px;
`;

const ContentGrid = styled.div<{
  columns: CSSProperties['gridTemplateColumns'];
  session: Session | null;
  marketplace: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  isolation: isolate;
  min-height: ${({ marketplace }) => (marketplace ? 'unset' : '100vh')};

  &#mobile.enabled ${StyledNavBar} {
    display: none;

    &${Main} {
      max-height: calc(
        ${globalTheme.sizes.fullHeight} - ${globalTheme.sizes.header}
      );
    }
  }

  &#mobile.enabled ${Main} {
    max-height: calc(
      ${globalTheme.sizes.fullHeight} - ${globalTheme.sizes.header}
    );
  }
`;

const NavBarWrapper = styled(motion.div)<{ display: CSSProperties['display'] }>`
  position: absolute;
  z-index: ${globalTheme.zIndices.navbar};
  display: ${({ display }) => display};
  height: 100%;
`;

export const AppLayout: FC<AppLayoutProps> = ({
  children,
  overrideHeaderContent,
  hideHeader = true,
  hideNav = true,
  hideHomeLink = false,
  marketplace = false,
  app = false,
  setTokenExpiry,
}) => {
  const isWide = useMedia(`(min-width: ${breakpoints.medium})`, true);
  const { data: session, status } = useSession();
  const router = useRouter();
  const sessionLoading = status === 'loading';
  const setAccessToken = useStore((store) => store.setAccessToken);

  const { data: user, isLoading: userLoading } = useUser();
  const { data: org } = useBuyerOrg();

  // The active ability for this user/org combo
  const [ability, setAbility] = useState<AppAbility>(defaultAbility);

  const userName = userLoading ? 'Loading...' : getUserName(user);

  // When toggle content grid columns based on animation state
  const [fullWidth, setFullWidth] = useState(hideNav);

  useEffect(() => {
    session?.expires && setTokenExpiry(session?.expires);
  }, [session?.expires]);

  // Update the store with the latest access token
  useEffect(() => {
    if (session?.accessToken && !sessionLoading) {
      logger.info('Setting access token', session.accessToken);
      setAccessToken(session.accessToken);
    }
  }, [session?.accessToken, sessionLoading, setAccessToken]);

  // Set ability according to latest user/org state
  useEffect(() => {
    if (user && org) {
      logger.info('Setting app ability');
      const newAbility = defineAbility(user, org);
      setAbility(newAbility);

      // Also update the tidio contact data
      if (window.tidioChatApi) {
        window.tidioChatApi.setContactProperties({
          organisation_name: org.name,
          organisation_id: org.buyer_org_id,
        });
      }
    }
  }, [org, user]);

  // Perform API version checking
  useVersionMatch();

  return (
    <>
      <AbilityContext.Provider value={ability}>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_KEY as string}
          autoBoot={false}
          autoBootProps={{
            verticalPadding: 80,
          }}
        >
          <WrapperGrid
            hideHeader={hideHeader}
            marketplace={marketplace}
            session={session}
            app={app}
          >
            {!hideHeader && session ? (
              <>
                <Header
                  userName={userName}
                  overrideContent={overrideHeaderContent}
                  businessName={org?.name}
                  hideHomeLink={hideHomeLink}
                />
              </>
            ) : (
              !session && marketplace && <PublicHeader />
            )}

            <ContentGrid
              columns={
                fullWidth || !session
                  ? '1fr'
                  : !isWide
                  ? '1fr'
                  : 'min-content 1fr'
              }
              session={session}
              marketplace={marketplace}
              id={'mobile'}
            >
              <AnimatePresence initial={false}>
                {!hideNav && session && isWide && (
                  <NavBarWrapper
                    key='nav'
                    animate='visible'
                    exit='hidden'
                    initial='hidden'
                    variants={navVariants}
                    onAnimationComplete={(def) => {
                      setFullWidth(def === 'hidden' ? true : false);
                    }}
                    display={session ? 'block' : 'none'}
                  >
                    <NavBar
                      buyerItems={buyerItems}
                      sellerItems={sellerItems}
                      mainItems={mainItems}
                    />
                  </NavBarWrapper>
                )}

                {!hideNav && session && !isWide && (
                  <StyledNavBar>
                    <BottomNavBar />
                  </StyledNavBar>
                )}
              </AnimatePresence>
              <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
                <UpgradeDialogProvider> {children}</UpgradeDialogProvider>
              </Sentry.ErrorBoundary>
            </ContentGrid>
          </WrapperGrid>
          <ReactQueryDevtools />
          <ToastContainer />
          <UserTermsDialog />
        </IntercomProvider>
      </AbilityContext.Provider>
    </>
  );
};
