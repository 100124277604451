import { Dialog, Button, Flex, Text, Icon, Checkbox } from 'components/ui';
import React, { useEffect, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';
import { isIOS } from 'react-device-detect';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { differenceInDays } from 'date-fns';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';

export interface IRelatedApp {
  id: string;
  platform: string;
  url: string;
}

const CButton = styled(Button)`
  width: 100%;
  margin-top: ${globalTheme.space[1]};
`;

const AppLogo = styled(Flex)`
  width: max-content;
  margin: auto;
`;
const IOSWrapper = styled(Flex)`
  margin-top: ${globalTheme.space[1]};
`;

type APP_VISIBILITY = 'REMIND' | 'DONOTSHOW';

function AddPwaDialog() {
  const [appVisibility, setAppVisibility] = useState<APP_VISIBILITY>('REMIND');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useMedia(`(max-width: ${breakpoints.small})`);
  const install = usePWAInstall();

  useEffect(() => {
    const now = new Date();
    const lastSeen = localStorage.getItem('APP_PROMO');
    const promoVisibility = localStorage.getItem('APP_PROMO_VISIBILITY');

    if (!install && !isIOS) {
      setIsOpen(false);
      return;
    }

    if (!isMobile) {
      setIsOpen(false);
      return;
    }

    if (
      (navigator as any)?.standalone ||
      window.matchMedia('(display-mode: standalone)').matches
    ) {
      setIsOpen(false);
      return;
    }

    // If no last_seen and app is installable
    if (!lastSeen) {
      setIsOpen(true);
    }
    // If last_seen and visibility is 'REMIND'
    if (lastSeen && promoVisibility !== 'DONOTSHOW') {
      const lastSeenDate = new Date(lastSeen);

      // Check if the difference is greater than 1 day
      if (differenceInDays(now, lastSeenDate) > 1) {
        setIsOpen(true);
      }
    }
  }, [install]);

  const handleModalClose = () => {
    const now = new Date();
    localStorage.setItem('APP_PROMO_VISIBILITY', appVisibility);
    localStorage.setItem('APP_PROMO', now.toString());
    setIsOpen(false);
  };

  const handleInstall = () => {
    install && install();
    handleModalClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title='Install Stockbook!'
      $smallDialogOnMobile
      showCancelIcon={false}
    >
      <AppLogo alignItems='center' gap={0}>
        <Icon component='AgoraLogo' dontFill size={5} />
        <Text fontSize={3} fontWeight='bold'>
          Stockbook
        </Text>
      </AppLogo>
      <Text textAlign='center' fontSize={3}>
        This site has app functionality. Add it to your Home Screen for
        extensive experience and easy access.
      </Text>
      {!isIOS && (
        <Flex
          direction='column'
          gap={0}
          alignItems='center'
          justifyContent='center'
        >
          <Flex direction='column' gap={2} padding={0}>
            <Checkbox
              label={`Remind me later`}
              checked={appVisibility === 'REMIND'}
              onChange={({ target }) => {
                if (target.checked) {
                  setAppVisibility('REMIND');
                } else {
                  setAppVisibility('DONOTSHOW');
                }
              }}
            />
            <Checkbox
              label={`Don't show this message again`}
              checked={appVisibility === 'DONOTSHOW'}
              onChange={({ target }) => {
                if (target.checked) {
                  setAppVisibility('DONOTSHOW');
                } else {
                  setAppVisibility('REMIND');
                }
              }}
            />
          </Flex>

          <CButton onClick={handleInstall} size='medium'>
            Install
          </CButton>
          <CButton onClick={handleModalClose} variant='outline' size='medium'>
            Close
          </CButton>
        </Flex>
      )}

      {isIOS && isMobile && (
        <IOSWrapper
          direction='column'
          gap={2}
          alignItems='center'
          justifyContent='center'
        >
          <Flex direction='column' gap={1}>
            <Flex gap={1} alignItems='center'>
              <Icon component='SafariBrowser' size={3} dontFill />
              <Text fontSize={2}>1) Open in Safari browser</Text>
            </Flex>

            <Flex gap={1} alignItems='center'>
              <Icon component='ShareIos' size={3} dontFill />
              <Text fontSize={2}>2) Press Share in Navigation bar</Text>
            </Flex>

            <Flex gap={1} alignItems='center'>
              <Icon component='AddSquare' size={3} dontFill />
              <Text fontSize={2}>
                3) Scroll to bottom and Press Add to Home Screen
              </Text>
            </Flex>

            <Flex direction='column' gap={2} padding={0}>
              <Checkbox
                name='app-visibility'
                label={`Remind me later`}
                checked={appVisibility === 'REMIND'}
                onChange={({ target }) => {
                  if (target.checked) {
                    setAppVisibility('REMIND');
                  } else {
                    setAppVisibility('DONOTSHOW');
                  }
                }}
              />
              <Checkbox
                name='app-visibility'
                label={`Don't show this message again`}
                onChange={({ target }) => {
                  if (target.checked) {
                    setAppVisibility('DONOTSHOW');
                  } else {
                    setAppVisibility('REMIND');
                  }
                }}
                checked={appVisibility === 'DONOTSHOW'}
              />
              <CButton size='small' onClick={handleModalClose}>
                Close
              </CButton>
            </Flex>
          </Flex>
        </IOSWrapper>
      )}
    </Dialog>
  );
}

export default AddPwaDialog;
